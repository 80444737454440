// extracted by mini-css-extract-plugin
export var artwork = "index-module--artwork--k-bKG";
export var artworkImg = "index-module--artwork-img--xQC+k";
export var buttonPurple = "index-module--button-purple--Bf9zt";
export var buttonRed = "index-module--button-red--furtN";
export var buttonYellow = "index-module--button-yellow--sE4nb";
export var desc = "index-module--desc--yBrw-";
export var row = "index-module--row--7ShHL";
export var tracklist = "index-module--tracklist--JZxIV";
export var tracklistLeft = "index-module--tracklist-left--ZF-UR";
export var tracklistRight = "index-module--tracklist-right--kx9iH";