import * as React from "react"
import './style.scss'
import * as styles from './index.module.scss'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'



const IndexPage = () => {
  return (
  <Layout pageTitle="Ambaras">


    <section className={`${styles.row} ${styles.tracklistRight} ${styles.buttonPurple}`}>
      <div className={styles.artwork}>
        <StaticImage
          alt="The artwork of Gnaj, a single"
          src="../images/gnaj_artwork.png"
          className={styles.artworkImg}
        />  
      </div>
      <div className={styles.desc}>
        <div className={styles.tracklist}>
            <h3>GNAJ</h3>
            <p>Singiel</p>
            <Link to="/stream/gnaj"><button aria-label="POSŁUCHAJ TUTAJ">POSŁUCHAJ TUTAJ</button></Link>
        </div>
      </div>
    </section>
    
    
    <section className={`${styles.row} ${styles.tracklistLeft} ${styles.buttonYellow}`}>
      <div className={styles.desc}>
      <div className={styles.tracklist}>
            <h3>STAĆ CIĘ NA WIĘCEJ</h3>
            <p>Singiel</p>
            <Link to="/stream/staccienawiecej"><button aria-label="POSŁUCHAJ TUTAJ">POSŁUCHAJ TUTAJ</button></Link>
        </div>
      </div>
      <div className={styles.artwork}>
        <StaticImage
          alt="The artwork of Stać cię na więcej, newest single"
          src="../images/staccienawiecej_artwork.jpg"
          className={styles.artworkImg}
        />  
      </div>
    </section>


    <section className={`${styles.row} ${styles.tracklistRight} ${styles.buttonRed}`}>
      <div className={styles.artwork}>
        <StaticImage
          alt="The artwork of Krec, a single"
          src="../images/krec_artwork.jpg"
          className={styles.artworkImg}
        />  
      </div>
      <div className={styles.desc}>
        <div className={styles.tracklist}>
            <h3>KRĘĆ</h3>
            <p>Singiel</p>
            <Link to="/stream/krec"><button aria-label="POSŁUCHAJ TUTAJ">POSŁUCHAJ TUTAJ</button></Link>
        </div>
      </div>
    </section>

    <section className={`${styles.row} ${styles.tracklistLeft} ${styles.buttonPurple}`}>
      <div className={styles.desc}>
        <div className={styles.tracklist}>
            <h3>PASTWISKO</h3>
            <ol>
              <li>DOBRANOC</li>
              <li>CHOWANY</li>
              <li>DAIQUIRI</li>
              <li>OWIECZKA</li>
              <li>1001 (BAŚŃ)</li>
              <li>PASTWISKO</li>
            </ol>
            <Link to="/stream/pastwisko"><button aria-label="POSŁUCHAJ TUTAJ">POSŁUCHAJ TUTAJ</button></Link>
        </div>
      </div>
      <div className={styles.artwork}>
        <StaticImage
          alt="The artwork of Pastwisko, the second EP"
          src="../images/pastwisko_artwork.jpg"
          className={styles.artworkImg}
        />  
      </div>
    </section>
    
    <section className={`${styles.row} ${styles.tracklistRight} ${styles.buttonRed}`}>
      <div className={styles.artwork}>
        <StaticImage
          alt="The artwork of Przestawienie, the EP"
          src="../images/przedstawienie_artwork.jpg"
          className={styles.artworkImg}
        />     
      </div>
      <div className={styles.desc}>
        <div className={styles.tracklist}>
           <h3>PRZEDSTAWIENIE</h3>
           <ol>
             <li>WEJŚCIE</li>
             <li>VLOG</li>
             <li>BŁOTKO</li>
             <li>DZBAN</li>
             <li>WCZORAJ</li>
             <li>WYJŚCIE</li>
           </ol>
           <Link to="/stream/przedstawienie"><button aria-label="POSŁUCHAJ TUTAJ">POSŁUCHAJ TUTAJ</button></Link>
        </div>
      </div>
    </section>
    
    </Layout>    
  )
}

export default IndexPage
